import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import ReCaptcha from "react-google-recaptcha"
import axios from "axios"

import { device, visuallyHidden } from "../utils/mixins"
import dsm from "../utils/dsm"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"
import { PageSectionTitle, I2BronzeText } from "../components/textComponents"

var FormContainer = styled(Form)`
  ${device.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    width: 99%;
    padding-left: 1%;
    padding-top: 1%;
  }

  label {
    ${visuallyHidden}
  }

  input {
    width: 100%;
    height: 2.75rem;
    font-size: 1.25rem;
    margin-bottom: 0.15rem;
    border-radius: 0.25rem;
    border: 0.05rem solid #979797;
    background: #eae7e7;
    padding-left: 0.75rem;

    ${device.tablet} {
      width: 48%;
    }
  }

  textarea {
    width: 100%;
    font-size: 1.25rem;
    margin-bottom: 0.15rem;
    border-radius: 0.25rem;
    border: 0.05rem solid #979797;
    background: #eae7e7;
    padding: 0.75rem;

    ${device.tablet} {
      width: 48%;
    }
  }
`

var NameInput = styled(Field)`
  border-color: ${props => (props.error ? "#de2424" : "#979797")} !important;
`

var PhoneInput = styled(Field)`
  border-color: ${props => (props.error ? "#de2424" : "#979797")} !important;
`

var MessageInput = styled(Field)`
  border-color: ${props => (props.error ? "#de2424" : "#979797")} !important;
`

var Image = styled(Img)`
  height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

var FormImage = styled(Img)`
  height: 100%;
`

var ContactUsCont = styled.div`
  ${device.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

var ContactMethodCont = styled.div`
  ${device.tablet} {
    width: 49%;
  }
`

var ContactMethodTitle = styled.h3`
  ${dsm.fonts.primary}
  font-size: 1.35rem;
  font-weight: 750;
  margin: 0 0 0.25rem;

  ${device.mobileM} {
    font-size: 1.45rem;
  }

  ${device.tablet} {
    font-size: 1.55rem;
    margin-bottom: 0.5rem;
  }
`

var ContactMethodTitleTextUs = styled(ContactMethodTitle)`
  margin-bottom: 0.25rem;
  margin-top: 3rem;

  ${device.mobileL} {
    margin-bottom: 0;
  }

  ${device.tablet} {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`

var ContactMethodValue = styled.div`
  font-weight: 750;
  color: ${dsm.color.brand.copper.dark};
  font-size: 1.35rem;

  ${device.mobileM} {
    font-size: 1.45rem;
  }

  ${device.tablet} {
    font-size: 1.55rem;
  }
`

var ErrorsContainer = styled.div`
  height: 1.5rem;

  span {
    font-size: 1rem;
    color: #de2424;
    font-weight: 600;
    margin-left: 0.25rem;

    ${device.mobileM} {
      font-size: 1.1rem;
    }
  }
`

var ContactMethodButton = styled.a`
  background: ${dsm.color.brand.copper.base};
  border: 0;
  color: black;
  text-decoration: none;
  border-radius: 1.25rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  ${dsm.fonts.primary};
  display: block;
  font-weight: 750;
  z-index: 1;
  box-shadow: 0.1rem 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
  transition: background 0.35s ease-out;
  width: 100%;
  text-align: left;
  padding-left: 1.25rem;
  margin: 0.75rem 0 2.5rem;

  :hover {
    background: #debc99;
  }

  ${device.mobileM} {
    padding: 0.75rem 0.65rem;
    padding-left: 1.25rem;
    font-size: 1.4rem;
  }

  ${device.tablet} {
    width: 95%;
    padding: 0.65rem;
    padding-left: 1.25rem;
    margin-top: 1rem;
  }
`

var TextUsButton = styled.button`
  background: ${props =>
    props.disabled ? "#ad947aad" : `${dsm.color.brand.copper.base}`};
  border: 0;
  color: ${props => (props.disabled ? "#00000099" : "black")};
  text-decoration: none;
  border-radius: 1.25rem;
  font-size: 1.25rem;
  padding: 0.5rem;
  ${dsm.fonts.primary};
  display: block;
  font-weight: 750;
  z-index: 1;
  box-shadow: 0.1rem 0.25rem 0.75rem rgba(0, 0, 0, 0.75);
  transition: background 0.35s ease-out;
  width: 100%;
  text-align: left;
  padding-left: 1.25rem;
  margin: 1.25rem 0 0.75rem;

  :hover {
    background: ${props => (props.disabled ? "bronze" : "#debc99")};
  }

  ${device.mobileM} {
    padding: 0.75rem 0.65rem;
    padding-left: 1.25rem;
    font-size: 1.4rem;
  }

  ${device.tablet} {
    width: 48%;
  }
`

var FullScreenFormPicCont = styled.div`
  display: none;
  position: absolute;
  height: 102%;
  width: 101%;
  z-index: -2;
  margin: 3% 0 4%;
  margin-left: -1%;
  margin-top: -1%;
  ${device.tablet} {
    display: block;
  }
`

var FormPicFilter = styled.div`
  display: none;
  position: absolute;
  height: 102%;
  width: 101%;
  z-index: -1;
  margin: 3% 0 4%;
  margin-left: -1%;
  margin-top: -1%;
  background: rgba(0, 0, 0, 0.7);

  ${device.tablet} {
    display: block;
  }
`

function ContactUs({ errors, touched, isSubmitting, setFieldValue }) {
  var data = useStaticQuery(graphql`
    query {
      contactTopImg: file(
        relativePath: {
          eq: "I2_Optimization_North_America_Drilling_Production_Completions.jpeg"
        }
      ) {
        ...fluidImageSVG
      }
      contactFormImg: file(
        relativePath: {
          eq: "i2O_Oil_and_Gas_Low_Emission_Low_Cost_Power_Solutions.jpeg"
        }
      ) {
        ...fluidImage
      }
    }
  `)

  function reCaptchaChange(val) {
    setFieldValue("reCaptchaValue", val)
  }

  return (
    <Layout>
      <SEO
        title="Contact Us"
        description="Get better results with a Completions Specialist. We do real world testing to show you the best options without the cost of multiple trials."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.contactTopImg.sharp.fluid}
          alt="Contact i2, Optimizing Drilling, Production, and Completions across North America"
        />
        <PageSectionTitle>
          Contact <I2BronzeText>Us</I2BronzeText>
        </PageSectionTitle>
      </PageTopFullSizeImgCont>

      <SectionContentContainer>
        <ContactUsCont>
          <ContactMethodCont>
            <ContactMethodTitle>Phone:</ContactMethodTitle>
            <ContactMethodValue>(250) 261 - 0068</ContactMethodValue>
            <ContactMethodButton href="tel:12502610068">
              Call Us
            </ContactMethodButton>
          </ContactMethodCont>

          <ContactMethodCont>
            <ContactMethodTitle>Email:</ContactMethodTitle>
            <ContactMethodValue>i2optimization@gmail.com</ContactMethodValue>
            <ContactMethodButton href="mailto:i2optimization@gmail.com">
              &nbsp;Email Us
            </ContactMethodButton>
          </ContactMethodCont>

          <ContactMethodTitleTextUs>Text Us:</ContactMethodTitleTextUs>

          <FormContainer errors={errors}>
            <FullScreenFormPicCont>
              <FormImage
                objectFit="cover"
                fluid={data.contactFormImg.sharp.fluid}
                alt="i2 Optimization, Low Cost, Low Emission Oil and Gas Power Solutions"
              />
            </FullScreenFormPicCont>
            <FormPicFilter />
            <label htmlFor="nameInput">Name:</label>
            <ErrorsContainer>
              {touched.name && errors.name && <span>{errors.name}</span>}
            </ErrorsContainer>
            <NameInput
              error={touched.name && errors.name ? 1 : 0}
              id="nameInput"
              placeholder="Name"
              type="text"
              name="name"
            />
            <label htmlFor="phoneNumber">Phone Number:</label>
            <ErrorsContainer>
              {touched.phoneNumber && errors.phoneNumber && (
                <span>{errors.phoneNumber}</span>
              )}
            </ErrorsContainer>
            <PhoneInput
              error={touched.phoneNumber && errors.phoneNumber ? 1 : 0}
              id="phoneNumber"
              placeholder="Phone Number"
              type="tel"
              name="phoneNumber"
            />
            <label htmlFor="userMessage">Your Message:</label>
            <ErrorsContainer>
              {touched.userMessage && errors.userMessage && (
                <span>{errors.userMessage}</span>
              )}
            </ErrorsContainer>
            <MessageInput
              error={touched.userMessage && errors.userMessage ? 1 : 0}
              component="textarea"
              placeholder="Your message..."
              id="userMessage"
              rows="10"
              cols="70"
              name="userMessage"
            />
            <ErrorsContainer>
              {touched.reCaptchaValue && errors.reCaptchaValue && (
                <span>{errors.reCaptchaValue}</span>
              )}
            </ErrorsContainer>
            <ReCaptcha
              onChange={reCaptchaChange}
              onBlur={reCaptchaChange}
              onExpired={reCaptchaChange}
              sitekey={process.env.RECAPTCHA_SITE_KEY}
            />
            <TextUsButton
              type="submit"
              // prettier-ignore
              disabled={isSubmitting}
            >
              &#8593; &nbsp; Send Message
            </TextUsButton>
          </FormContainer>
        </ContactUsCont>
      </SectionContentContainer>
    </Layout>
  )
}

var Contact = withFormik({
  mapPropsToValues({ name, phoneNumber, userMessage, reCaptchaValue }) {
    return {
      name: ``,
      phoneNumber: ``,
      userMessage: ``,
      reCaptchaValue: ``,
    }
  },
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .matches(/^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,]{1,25}$/, {
        message: "Please provide your name.",
      })
      .required("Please provide your name."),
    phoneNumber: Yup.string()
      .matches(
        /^\s*(?:\+?(\d{1}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        { message: "Enter phone number with area code." }
      )
      .required("Please provide your phone number."),
    userMessage: Yup.string()
      .matches(/^(?=.*[A-Z0-9])[\w\s.,!?"'/()$ ]+$/i, {
        message: "Use letters, numbers, or punctuation.",
      })
      .required("Please add a message."),
    reCaptchaValue: Yup.string().required("Please check the box below."),
  }),
  handleSubmit(values, { setSubmitting }) {
    async function handleInputs(values) {
      var { name, phoneNumber, userMessage, reCaptchaValue } = values

      var contactName = name.trim()
      var contactPhone = phoneNumber.trim()
      var contactMessage = userMessage.trim()

      await axios({
        url:
          "https://lw5xxirgx0.execute-api.us-east-1.amazonaws.com/dev/contact-us-message",
        method: "post",
        data: {
          contactName: contactName,
          contactPhone: contactPhone,
          contactMessage: contactMessage,
          reCaptchaValue: reCaptchaValue,
        },
      })
        .then(function axiosReturn() {
          navigate("/contact-us-received")
        })
        .catch(function axiosRequestFail() {
          alert(
            "Our Message Server is Down At the Moment. To Get In Touch, Use the Call Us or Email Us Button."
          )
        })
    }
    handleInputs(values)
    setSubmitting(false)
  },
})(ContactUs)

export default Contact
